<template>
<v-container row justify-center>
    <v-dialog max-width="600px" persistent v-model="isShowDetails">
        <v-card ref="formCard">
            <v-card-title class="headline">
                {{details.title}}
            </v-card-title>
            <v-card-text>
               <v-form ref="formEntry"> 
                <v-row v-if="isValidStatus('FOR_APPROVAL')">
                    <v-col cols="12">
                        <v-flex xs12>
                            <v-autocomplete
                                    v-model="form.bankAccount" 
                                    :items="bankAccountList"
                                    :item-text="getItemText"
                                    label="Bank Account*" 
                                    required :rules="[rules.requiredField]"
                                    data-cy="form-payment-agent"
                                    clearable
                                    return-object>
                                </v-autocomplete>
                        </v-flex>
                    </v-col>
                    <v-col cols="12">
                        <v-flex xs12>
                            <v-text-field label="Bank Reference*" v-model="bankReference" required :rules="[rules.requiredField]"></v-text-field>
                        </v-flex>
                    </v-col>
                    <v-col cols="12">
                        <v-flex xs12>
                            <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field label="Transaction Date*" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" required :rules="[rules.requiredField]"></v-text-field>
                                </template>
                                <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax"></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-col>
                </v-row>
                <v-row v-for="(field, index) of getFields()" :key="index">
                    <v-col cols="12" v-if="isJSON(field[1])">
                        <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly data-cy="form-label-area"></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly data-cy="form-input-text-field"></v-text-field>
                    </v-col>
                </v-row>
               </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="forApproval" v-if="isValidStatus('APPROVED')" data-cy="form-button-approve">
                    APPROVE
                </v-btn>
                <v-btn color="green darken-1" text @click="toSettle" v-if="isValidStatus('FOR_APPROVAL')" data-cy="form-button-settle">
                    SETTLE
                </v-btn>
                <v-btn color="green darken-1" text @click="close" data-cy="form-button-close">
                    Close
                </v-btn>
                <!-- <v-btn color="green darken-1" text @click="sendReversal" v-if="isValidReverseStatus('REVERSED')">
                    Send Reversal
                </v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        details: Object,
        listOfBankAccounts: Array,
    },
    watch: {
        isShow: {
            handler(value) {
                this.isShowDetails = value
            }
        },
        listOfBankAccounts: {
            handler(value) {
                this.bankAccountList = value
            }
        },
        deep: true,
    },
    computed: {
        populatedForm() {
            this.details.item.bank = this.form.bankAccount.bank
            this.details.item.bankReference = this.bankReference
            this.details.item.bankTransactionDate = this.fromDateVal
            this.details.item.bankAccountNumber = this.form.bankAccount.accountNumber
        },
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
    },
    details: {
        handler(value) {
            this.itemDetails = value
        },
        deep: true,
    },
    data() {
        return {
            isShowDetails: this.isShow ?? false,
            itemDetails: this.details,
            fromDateMenu: false,
            fromDateVal: "",
            bank: "",
            form: {
                bankAccount: '',
            },
            bankAccountList: this.listOfBankAccounts ?? [],
            bankReference: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            rules: {
                requiredField: v => !!v || "Required field.",
            },
        }
    },
    methods: {
        getItemText(item) {
            return `${item.bank}` + ":" + `${item.accountNumber}`;
        },
        getFields() {
            const arrOfArrays = Object.entries(this.itemDetails.item);

            const sorted = arrOfArrays.sort((a, b) => {
            return a[0].localeCompare(b[0]);
            });

            return sorted;
        },
        convertCamelCase(string) {
            return string.replace(/([a-z])([A-Z])/g, '$1 $2');
        },
        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'details')
        },
        isJSON(value) {
          return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
        },
        stringify(value) {
          if(Object.prototype.toString.call(value) === '[object Array]') {
            var items = []
            for(var index in value) {
              if(value[index].role) {
                items.push(value[index].role)
              }
            }
            if(items.length > 0) {
              return items.toString()
            }
            return JSON.stringify(value)
          } else if(Object.prototype.toString.call(value) === '[object Object]') {
            if(value.role) {
              return value.role
            }
            return JSON.stringify(value)
          } else {
            return value.toString()
          }
        },
        forApproval() {
            if (this.$refs.formEntry.validate()) {
                this.$emit("update-status", this.populatedForm);
                this.$refs.formCard.$el.scrollIntoView({
                behavior: "smooth",
                });
            }
        },
        toSettle() {
            if (this.$refs.formEntry.validate()) {
                this.$emit("settle-status", this.populatedForm);
                this.$refs.formCard.$el.scrollIntoView({
                behavior: "smooth",
                });
            }
        },
        isValidStatus(status) {
            if(this.details.status === status) {
                return false
            } else {
                if(this.details.status === "FOR_APPROVAL" || this.details.status === "APPROVED") {
                    return true
                } else {
                    return false
                }
            }
        }, 
    }
}
</script>

<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" :listOfBankAccounts="listOfBankAccounts" v-on:close-dialog="closeDialog" v-on:update-status="updateBatchStatusToApprove(details.item)" v-on:settle-status="updateBatchStatusToSettle(details.item)" data-cy="form-details"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Settlement</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col cols="12" md="5">
                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="5">
                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                        <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-row class="ma-1">
            <v-col class="d-flex" cols="12" md="4">
                <v-text-field class="px-4" label="Settlement Reference" single-line @change="searchSettlementReference" data-cy="text-field-settlement-reference"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="2">
                <v-autocomplete
                    clearable chips
                    :items="merchantCodes" 
                    label="Merchant ID" 
                    v-model="filteredTransaction.merchantCode" 
                    return-object
                    data-cy="merchant-code-list"
                    @change="searchMerchantCode">
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-card-actions elevation="2" class="container--fluid grid-list-md text-center" data-cy="action-button-search">
                    <v-btn dark medium color=success @click="queryData(true)" data-cy="button-search">
                        <v-icon dark left> mdi-magnify</v-icon>Search
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-card>

    <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
    <!-- <div class="col-md-6 text-right">
        <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
    </div> -->

    <template>
        <v-card>
            <v-tabs marginTop="100" v-model="tab" background-color="transparent" color="blue" >
                    <v-tab data-cy="tab-for-approval">
                        FOR APPROVAL
                    </v-tab>
                    <v-tab data-cy="tab-approved">
                        APPROVED
                    </v-tab>
                    <v-tab data-cy="tab-settled">
                        SETTLED
                    </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-data-table :hide-default-header="true" :headers="headers" :items="displayForApprovalBatch" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalForApprovalList" :footer-props="{'items-per-page-options': [10]}">
                        <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.settlementReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.totalAmount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.totalCount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                                        <td>
                                            <span class="d-flex justify-center">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-button-details">
                                                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>details</span> 
                                                </v-tooltip>    
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="download(item,formatCSV)" data-cy="list-action-button-csv">
                                                        <v-icon dark medium>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>Download CSV</span> 
                                                </v-tooltip> 
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green"  @click.stop="download(item,formatPDF)" data-cy="list-action-button-pdf">
                                                        <v-icon dark medium>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>Download PDF</span> 
                                                </v-tooltip>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                    Found no results.
                                </v-alert>
                            </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table :hide-default-header="true" :headers="headers" :items="displayApprovedBatch" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalApprovedList" :footer-props="{'items-per-page-options': [10]}">
                        <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.settlementReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.totalAmount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.totalCount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                                        <td>
                                            <span class="d-flex justify-center">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-button-details">
                                                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>details</span> 
                                                </v-tooltip>    
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="download(item,formatCSV)" data-cy="list-action-button-csv">
                                                        <v-icon dark medium>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>Download CSV</span> 
                                                </v-tooltip> 
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green"  @click.stop="download(item,formatPDF)" data-cy="list-action-button-pdf">
                                                        <v-icon dark medium>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>Download PDF</span> 
                                                </v-tooltip>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                    Found no results.
                                </v-alert>
                            </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table :hide-default-header="true" :headers="headers" :items="displaySettledBatch" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalSettledList" :footer-props="{'items-per-page-options': [10]}">
                        <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.settlementReference }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.totalAmount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.totalCount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                                        <td>
                                            <span class="d-flex justify-center">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-button-details">
                                                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>details</span> 
                                                </v-tooltip>    
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="download(item,formatCSV)" data-cy="list-action-button-csv">
                                                        <v-icon dark medium>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>Download CSV</span> 
                                                </v-tooltip> 
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green"  @click.stop="download(item,formatPDF)" data-cy="list-action-button-pdf">
                                                        <v-icon dark medium>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                    </template>
                                                   <span>Download PDF</span> 
                                                </v-tooltip>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                    Found no results.
                                </v-alert>
                            </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>        
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/SettlementBatchForm.vue'
import moment from 'moment';

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
    },
    data() {
        return {
            headers: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Merchant Code',
                    value: 'merchantCode'
                },
                {
                    text: 'Settlement Reference',
                    value: 'settlementReference'
                },
                {
                    text: 'Total Amount',
                    value: 'totalAmount'
                },
                {
                    text: 'Transaction Count',
                    value: 'totalCount'
                },
                {
                    text: 'Creation Date',
                    value: 'dateCreated'
                },
                {
                    text: 'Status',
                    value: 'status'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfMerchantCodes: [],
            listOfBankAccounts: [],
            merchantCodes: this.listOfMerchantCodes ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalForApprovalList: 0,
            totalApprovedList: 0,
            totalSettledList: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayForApprovalBatch: [],
            displayApprovedBatch: [],
            displaySettledBatch: [],
            remainderForApprovalBatch: [],
            remainderApprovedBatch: [],
            remainderSettledBatch: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            selected: [],
	    	selectAll: false,
            totalCount:'',
            tab: null,
            formatCSV: "csv",
            formatPDF: "pdf"

        }
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
                this.setData();
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getMerchantCode()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.merchantCodes
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getBankAccounts()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfBankAccounts = listResponse.bankAccounts
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var settlementBatchlist = undefined
                const batchStatus = this.tab === 0 ? 'FOR_APPROVAL' : this.tab === 1 ? 'APPROVED' : 'SETTLED'
                
                const parameters = {
                    merchantCode: this.filteredTransaction.merchantCode,
                    settlementReference: this.filteredTransaction.settlementReference,
                    dateFrom: this.fromDateVal, 
                    dateTo: this.toDateVal,
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                settlementBatchlist = await API.getSettlementBatch(parameters,batchStatus)

                    
                if (!settlementBatchlist || settlementBatchlist.error) {
                    console.log(`${settlementBatchlist.error}`)
                } else {
                    var filteredList = settlementBatchlist.settlementBatchList
                    var length = settlementBatchlist.rowCount
                    if(batchStatus === 'FOR_APPROVAL' || batchStatus === 'PROCESSING') {
                        if (page > 1 && this.remainderForApprovalBatch.length > 0) {
                            filteredList = this.remainderForApprovalBatch.concat(filteredList)
                        }
                        // this.totalForApprovalList = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        this.totalForApprovalList = length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayForApprovalBatch = filteredList.slice(0, end)
                        this.remainderForApprovalBatch = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = this.totalForApprovalList / itemsPerPage

                        parameters.offset = this.totalForApprovalList
                        parameters.limit = 1
                        
                        const hasMoreResponse = settlementBatchlist
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.settlementBatchList
                            if(hasMoreList.length > 0) {
                            // this.totalForApprovalList = Math.floor(this.totalForApprovalList/10) * 10 + 1
                            this.numberOfPages = this.numberOfPages + 1
                            }
                        }
                    } else if (batchStatus === 'APPROVED') {
                        if (page > 1 && this.remainderApprovedBatch.length > 0) {
                            filteredList = this.remainderApprovedBatch.concat(filteredList)
                        }
                        // this.totalApprovedList = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        this.totalApprovedList = length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayApprovedBatch = filteredList.slice(0, end)
                        this.remainderApprovedBatch = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = this.totalApprovedList / itemsPerPage

                        parameters.offset = this.totalApprovedList
                        parameters.limit = 1
                        
                        const hasMoreResponse = settlementBatchlist
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.settlementBatchList
                            if(hasMoreList.length > 0) {
                            // this.totalApprovedList = Math.floor(this.totalApprovedList/10) * 10 + 1
                            this.numberOfPages = this.numberOfPages + 1
                            }
                        }
                    } else {
                        if (page > 1 && this.remainderSettledBatch.length > 0) {
                            filteredList = this.remainderSettledBatch.concat(filteredList)
                        }
                        // this.totalSettledList = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        this.totalSettledList = length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displaySettledBatch = filteredList.slice(0, end)
                        this.remainderSettledBatch = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = this.totalSettledList / itemsPerPage

                        parameters.offset = this.totalSettledList
                        parameters.limit = 1
                        
                        const hasMoreResponse = settlementBatchlist
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.settlementBatchList
                            if(hasMoreList.length > 0) {
                            // this.totalSettledList = Math.floor(this.totalSettledList/10) * 10 + 1
                            this.numberOfPages = this.numberOfPages + 1
                            }
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        searchMerchantCode: async function (val) {
            this.filteredTransaction.merchantCode = val
            // this.queryData(true)
        },
        searchSettlementReference(val) {
            this.filteredTransaction.settlementReference = val
            // this.queryData(true)
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.status = transaction.status
            this.details.item = transaction
            this.isShowDetails = true
        },
        async download(transaction,format) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if(format === "csv") {
                this.loadingMessage = `Downloading CSV please wait...`
            }else {
                this.loadingMessage = `Downloading PDF please wait...`
            }
            const downloadResponse = await API.exportSettlementToCSVorPDF(transaction,format)
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        async updateBatchStatusToSettle(value) {
             this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Updating settlement batch: <strong><i>${value.settlementReference}</i></strong> Status to settle`
                setTimeout(async () => {
                    const createResponse = await API.updateSettlementBatchToSettle(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully updated settlement batch: <strong><i>${value.settlementReference}</i> status</strong>`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        async updateBatchStatusToApprove(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Updating settlement batch: <strong><i>${value.id}</i></strong> status to approve`
                setTimeout(async () => {
                    const createResponse = await API.updateSettlementBatchToApprove(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully updated settlement batch: <strong><i>${value.id}</i></strong> status`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
    }
};
</script>
